body {
  --privy-border-radius-sm: 4px;
  --privy-border-radius-md: 6px;
  --privy-border-radius-mdlg: 8px;
  --privy-border-radius-lg: 12px;
  --privy-color-background: #181c20;
  --privy-color-background-2: #1f2429;
  --privy-color-background-3: #252b31;
  --privy-color-foreground: #ffffff;
  --privy-color-foreground-2: rgba(255, 255, 255, 0.8);
  --privy-color-foreground-3: rgba(255, 255, 255, 0.6);
  --privy-color-foreground-4: rgba(255, 255, 255, 0.4);
  --privy-color-foreground-accent: #ffffff;
  --privy-color-accent: #cc38ff;
  --privy-color-accent-light: #cc38ff;
  --privy-color-accent-lightest: #cc38ff;
  --privy-color-accent-dark: #cc38ff;
  --privy-color-accent-darkest: #cc38ff;
  --privy-color-success: #00ee91;
  --privy-color-error: #ff005c;
  --privy-color-error-light: #ff005c;
  --privy-color-warn: #ffcb23;
}
