:root,
pre {
  font-family: 'HarmonyOS Sans', Inter, monospace;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root,
  pre {
    font-family: 'HarmonyOS Sans', InterVariable, monospace;
    font-feature-settings: 'tnum' 1, 'liga' 1, 'calt' 1, 'cv01' 1, 'cv09' 1,
      'cv03' 1, 'cv04' 1;
  }
}

html {
  box-sizing: border-box;
  scrollbar-color: #302f3850 transparent;
  scrollbar-width: thin;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:focus-visible {
  outline: 2px solid #302f3850;
  outline-offset: 2px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: transparent;
  background-clip: padding-box;
  border: 8px solid transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  color: #302f3850;
}

::-webkit-scrollbar-corner {
  background: #302f3850;
}

body {
  background-color: #0b0e12;
  font-weight: 500;
  width: 100vw;
  height: 100vh;
  color: white;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none; /* no underline */
}

button {
  cursor: pointer;
}

input {
  padding: 0;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

[data-reach-menu-popover] {
  z-index: 2; /* Over tooltips */
}
