@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Thin.eot');
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Thin.eot?#iefix')
      format('embedded-opentype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Thin.woff2')
      format('woff2'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Thin.woff')
      format('woff'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Thin.ttf')
      format('truetype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Thin.svg#HarmonyOS_Sans_Thin')
      format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans.eot');
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans.eot?#iefix')
      format('embedded-opentype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans.woff2')
      format('woff2'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans.woff')
      format('woff'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans.ttf')
      format('truetype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans.svg#HarmonyOS_Sans')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Light.eot');
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Light.eot?#iefix')
      format('embedded-opentype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Light.woff2')
      format('woff2'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Light.woff')
      format('woff'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Light.ttf')
      format('truetype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Light.svg#HarmonyOS_Sans_Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Medium.eot');
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Medium.eot?#iefix')
      format('embedded-opentype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Medium.woff2')
      format('woff2'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Medium.woff')
      format('woff'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Medium.ttf')
      format('truetype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Medium.svg#HarmonyOS_Sans_Medium')
      format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Black.eot');
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Black.eot?#iefix')
      format('embedded-opentype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Black.woff2')
      format('woff2'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Black.woff')
      format('woff'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Black.ttf')
      format('truetype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Black.svg#HarmonyOS_Sans_Black')
      format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Bold.eot');
  src: url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Bold.eot?#iefix')
      format('embedded-opentype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Bold.woff2')
      format('woff2'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Bold.woff')
      format('woff'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Bold.ttf')
      format('truetype'),
    url('https://app.paradex.trade/fonts/HarmonyOS_Sans/HarmonyOS_Sans_Bold.svg#HarmonyOS_Sans_Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
